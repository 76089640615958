import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AKWebSocket from './Components/AKWebSocket'

export const ws = new AKWebSocket("wss://www.abhiline.com/bongo")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
       <App />
  </React.StrictMode>
);

