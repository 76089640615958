import bannerLogo from './Assets/bannerLogo.png'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { navigate } from './Components/AKRouter';

export default function Points({points, email, level}) {

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="HomePage">
            <div className="Logo">
                <img src={bannerLogo}></img>
            </div>


            <div className="LoginButton">
                <br/><br/><hr/><br/>
                <h2>Jabe Loyalty Programme</h2>
                <p>Account Name: {email}</p>
                <p>Account Level: {level}</p>
                <br/><br/>
                <div className="center-horizontally"><div className="circle">{numberWithCommas(points)} Points</div></div>
                <br/><br/><hr/><br/>
            </div>
            <div className="Main">
                <p>Jabe Loyalty Programme deepens the relationship with our valued partners by rewarding them with loyalty points for every tour they book with Jabe. Each 100 loyalty points is equivalent to US$1 and can be redeemed on future tours booked with Jabe Concierge for the personal travel of the Loyalty Card member. For more details, including a statement breaking down your points to date, please e-mail vip@jabe.jz.</p>
            </div>
            <div className="RegisterButton">
                <br/>
                <Button variant="contained" onClick={(e) => navigate("/home")} fullWidth>Logout</Button>
            </div>




        </div>
    )
}
