import {useContext} from 'react'

import Home from './Home'
import Success from './Register'
import Login from './Login'
import Points from './Points'
import AKRouter from './Components/AKRouter'
import './App.css'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {useEffect} from 'react';

import {ws} from './index'

import {ThemeProvider, useTheme, createTheme} from '@mui/material/styles';

import {amber, deepOrange, grey} from '@mui/material/colors';

/* Try also npm i webfontloader

import WebFont from 'webfontloader';

WebFont.load({
    custom: {
        families: ['roboto'],
    },
})

*/

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        primary: {
            ...'#00AFCA',
            ...(mode === 'dark' && {
                main: '#4f4b1b',
            }),
        },
        text: {
            ...(mode === 'light'
                ? {
                    primary: grey[900],
                    secondary: grey[800],
                }
                : {
                    primary: '#fff',
                    secondary: grey[500],
                }),
        },
    },
    overrides: {
        MuiFilledInput: {
            input: {
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px #000 inset',
                    '-webkit-text-fill-color': '#fff'
                }
            }
        }
    }
});


function App() {

    useEffect(() => {
        document.title = "Jabe Concierge"
    }, []);

    const theme = useTheme();

    const darkModeTheme = createTheme(getDesignTokens('dark'));

    const routes = [
        {path: "/", component: <Home/>},
        {path: "/register", component: <Success/>},
        {path: "/login", component: <Login/>},
        {path: "/points", component: <Points/>}
    ]

    const defaultComponent = <Home/>

    return (
        <>
            <ThemeProvider theme={darkModeTheme}>
                <AKRouter routes={routes} defaultComponent={defaultComponent}/>
            </ThemeProvider>
        </>
    )
}


export default App
