import {navigate} from './Components/AKRouter'
import Button from '@mui/material/Button';
import bannerLogo from './Assets/bannerLogo.png'

export default function Success() {

    return (
        <div className="HomePage">
            <div className="Logo">
                <img src={bannerLogo}></img>
            </div>


            <div className="LoginButton">
                <br/><br/><hr/><br/>
                <h2>Thank you for Registering!</h2>
                <br/><hr/><br/>
            </div>
            <div className="Main">
                <br/><p>You will receive a personal e-mail from the Jabe Team in the coming days with more details on the Jabe Loyalty Programme.</p>
            </div>
            <div className="RegisterButton">
                <br/><br/>
                <Button variant="contained" onClick={(e) => navigate("/home")} fullWidth>Logout</Button>
            </div>




        </div>
    )
}