import bannerLogo from './Assets/bannerLogo.png'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { navigate } from './Components/AKRouter';
import {useState} from "react";
import {CommandRequest, LoginData} from "./DataModel";
import {ws} from "./index";
import Points from "./Points"

export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordHelperText, setPasswordHelperText] = useState("");

    const [points, setPoints] = useState(0);
    const [showPoints, setShowPoints] = useState(false);
    const [level, setLevel] = useState("Silver");

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function login() {
        if (validateEmail(email)) {
            let command = new CommandRequest("login", email, new LoginData(email, password))
            ws.sendRequest(command,
                (value) => {
                switch (value.data) {
                    case "E-mail does not exist.":
                        setEmailError(true)
                        setEmailHelperText("E-mail not yet registered.")
                        break;
                    case "Incorrect Password.":
                        setPasswordError(true)
                        setPasswordHelperText("Incorrect Password.")
                        break;
                    default:
                        let {points, level } = value.data
                        setPoints(points)
                        setShowPoints(true)
                        setLevel(level)
                        break;
                    }
                })
        } else {
            setEmailError(true)
            setEmailHelperText("E-mail not in a valid format")
        }
    }

    function handleEmailChange(e) {
        setEmail(e.target.value)

        setEmailError(false)
        setEmailHelperText("")

        if (validateEmail(e.target.value)) {
            setEmailError(false)
            setEmailHelperText("")
        }
    }

    function handlePasswordChange(e) {
        setPasswordError(false)
        setPasswordHelperText("")
        setPassword(e.target.value)
    }

       if (showPoints) {
          return (<Points points={points} email={email} level={level}/>)
       } else {
           return (
           <div className="HomePage">
               <div className="Logo">
                   <img src={bannerLogo}></img>
               </div>


               <div className="LoginButton">
                   <br/><br/><hr/><br/>
                   <TextField error={emailError} helperText={emailHelperText} data={email} label="E-mail" onChange={handleEmailChange} variant="filled" fullWidth/><br/><br/>
                   <TextField error={passwordError} helperText={passwordHelperText} data={password} label="Password" onChange={handlePasswordChange} variant="filled" type="password" fullWidth/><br/><br/>
                   <Button variant="contained" onClick={() => login()} fullWidth>Login</Button>
                   <br/><br/><hr/><br/>
               </div>

               <div className="RegisterButton">
                   <Button variant="contained" onClick={(e) => navigate("/")} fullWidth>Register</Button>

               </div>

           </div>
           )
       }
}
