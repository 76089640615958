import bannerLogo from './Assets/bannerLogo.png'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { navigate } from './Components/AKRouter';
import {useState} from 'react';
import {ws} from './index'
import {CommandRequest, RegisterData} from './DataModel'

export default function Home() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("")
    const [occupation, setOccupation] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [passError, setPassError] = useState(false);
    const [passHelperText, setPassHelperText] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState("");

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function register() {
        if (password === rePassword) {
            if (validateEmail(email)) {
                let command = new CommandRequest("register", email,
                    new RegisterData(firstName, lastName, occupation, company, email, password))
                ws.sendRequest(command,
                    (value) => {
                        switch (value.data) {
                            case "Account created.":
                                navigate("/register")
                                break;
                            default:
                                break;
                        }
                    })
            } else {
                setEmailError(true)
                setEmailHelperText("E-mail not in a valid format")
            }
        } else {
            setPassError(true)
            setPassHelperText("Passwords Must Match")
        }
    }

    function handleEmailChange(e) {
        setEmail(e.target.value)

        if (validateEmail(e.target.value)) {
            setEmailError(false)
            setEmailHelperText("")
        }
    }

    function handleRePasswordChange(e) {
        setRePassword(e.target.value)

        if (password === e.target.value) {
            setPassError(false)
            setPassHelperText("")
        }
    }

    return (
        <div className="HomePage">
            <div className="Logo">
                <img src={bannerLogo}></img>
            </div>

            <div className="LoginButton">
                <br/><br/><hr/><br/>
                <Button variant="contained" onClick={() => navigate("/login")} fullWidth>Login</Button>
                <br/><br/><hr/><br/>
            </div>

            <div className="Main">
                <br />
                <TextField data={firstName} label="First Name" onChange={(e) => setFirstName(e.target.value)} variant="filled" fullWidth/><br /><br/>
                <TextField data={lastName} label="Last Name" onChange={(e) => setLastName(e.target.value)} variant="filled" fullWidth/><br/><br/>
                <TextField data={occupation} label="Occupation / Job Title" onChange={(e) => setOccupation(e.target.value)} variant="filled" fullWidth/><br/><br/>
                <TextField data={company} label="Company" onChange={(e) => setCompany(e.target.value)} variant="filled" fullWidth/><br/><br/>
                <TextField error={emailError} helperText={emailHelperText} data={email} label="E-mail" onChange={handleEmailChange} variant="filled" fullWidth/><br/><br/>
                <TextField data={password} label="Password" onChange={(e) => setPassword(e.target.value)} variant="filled" type="password" fullWidth/><br/><br/>
                <TextField error={passError} helperText={passHelperText} data={rePassword} label="Re-enter Password" onChange={handleRePasswordChange} variant="filled" type="password" fullWidth/><br/><br/>
            </div>

            <div className="RegisterButton">
                <Button variant="contained" onClick={(e) => register()} fullWidth>Register</Button>

            </div>




        </div>
    )
}
