export class CommandRequest {
    constructor(command, email, data) {
        this.command = command;
        this.email = email;
        this.data = data;
    }
}

export class LoginData {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }
}

export class RegisterData {
    constructor(firstName, lastName, occupation, company, email, password) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.occupation = occupation;
        this.company = company;
        this.email = email;
        this.password = password;
    }
}